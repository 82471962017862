import React from "react";
import "../styles/globals.scss";
import "../components/style.css";
// import "./style.css";
import "../public/assets/bootstrap/bootstrap-grid.css";
import "react-circular-progressbar/dist/styles.css";
import "plyr-react/plyr.css";
import "react-phone-input-2/lib/style.css";
import "react-image-lightbox/style.css";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";

import dynamic from "next/dynamic";
import Image from "next/image";
const Providers = dynamic(() => import("../containers/providers"));
import { logoDark } from "../public/assets";

// Lazy load Entry component (since it's not SEO-critical)
const LazyEntry = dynamic(() => import("../components/Entry"), {
  ssr: false, // Disable server-side rendering
  loading: () => (
    <div
      className="loading__wrapper"
      style={{ display: "flex" }}
      data-nosnippet=""
    >
      <div className="loading__content fadeIn">
        <Image
          height={100}
          width={100}
          src={logoDark}
          alt="Pustack Logo"
          className="loader__logo"
        />
        <span id="loader" />
      </div>
    </div>
  ), // Optional loading state while Entry is being loaded
});
// import dynamic from "next/dynamic";

// const UserContextProvider = dynamic(
//   () => import("../context/global/user-context")
// );
// const IntroContextProvider = dynamic(
//   () => import("../context/global/IntroContext")
// );
// const ThemeContextProvider = dynamic(
//   () => import("../context/global/ThemeContext")
// );

export default function App({ Component, pageProps }: any) {
  if (Component.getLayout === "BlogPage") {
    return (
      <Providers>
        <Component {...pageProps} />;
      </Providers>
    );
  }

  return <LazyEntry Component={Component} pageProps={pageProps} />;
}

// const EmptyLayout = ({ children }) => <>{children}</>;
